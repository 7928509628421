/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import TitleDefault from 'components/elements/TitleDefault'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import Markten from 'components/shared/Markten'

const MarketImage = styled(Image)` 
  width: 100%;
`

const MapImage = styled(Image)`
  height: 450px;
  width: 100%;
  object-fit: cover;

  @media (max-width: 991px){
    height: 275px;
  }
`

const WhiteSection = styled.section`
  position: relative;
  z-index: 2;

  & .bg-white {
    height: 50px;
  }
`

const IframeSection = styled.section`
  position: relative;
  z-index: 1;
  margin-top: -98px;

  & iframe {
    border: 0;
    pointer-events: none;
  }
`

const PageTemplate = ({
  data: {
    page: { title, path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />

      <section>
        <div className="container py-lg-3">
          <div className="row py-5">
            <div className="col-lg-5 pt-lg-5 ">
              <TitleDefault>{acf.banner.title}</TitleDefault>
              <ParseContent className="pr-5" content={acf.banner.description} />
            </div>
            <div className="col-lg-7">
              <MarketImage src={acf.banner.image} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row pt-5">
            <Markten columns />
          </div>
        </div>
      </section>

    <WhiteSection>
      <div className="col-12 bg-white" />
    </WhiteSection>

      <IframeSection className="py-5">
        <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1_nkOHQEUsJaZfO2SySz3lds05MwESi4N" width="100%" height="480" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0" ></iframe>
      </IframeSection>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          title
          description
          image {
            localFile {
              publicURL
            }
          }
        }
        maps {
          image {
            localFile {
              publicURL
            }
          }
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
